.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  padding: 10px;
  /* Add any additional styles here */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo-1 {
    animation: App-logo-spin infinite 20s linear;
  }
}

html {
  font-family: PipersPlayroom;
}

.field {
  width: 100%;
  max-width: 40vh;
  height: 56px;
  border: unset;
  position: relative;
  background-color: rgba(255,255,255,0.3);
  transition: 0.3s all;
  font-size: 4vh;
  /* color: white; */
  padding: 10px;
  margin: 10px;
}

.field::placeholder {
  color: #b3b3b3; /* Placeholder text color */
}

.field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.button {
  width: 100%;
  max-width: 40vh;
  height: 56px;
  position: relative;
  background-color: rgba(255,255,255,0.3);
  transition: 0.3s all;
  border: unset;
  font-size: 5vh;
  padding: 10px;
  margin: 10px;
  background-color: #FFB6C1;
}

.button:active {
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.button:hover {
  filter: brightness(80%);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.input-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  /* color: white; */
}
.input-form>* {
  /*Select only 1 level children */
  margin-bottom: 1em;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wedding-card {
  width: 300px;
  padding: 20px;
  margin: 0 auto;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1), -3px -3px 8px rgba(255, 255, 255, 0.7);
  font-family: 'Garamond', serif;
  text-align: center;
}
